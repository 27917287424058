<template>
  <section v-if="!isLoading">
    <b-sidebar
      type="is-primary-dim-blue"
      :fullheight="true"
      :fullwidth="false"
      :overlay="overlay"
      :right="false"
      :reduce="reduce"
      :open.sync="open"
      :can-cancel="canCancel"
    >
      <div class="p-1">
        <b-image
          :src="require('@/assets/sidebar-dashboard/HK_Logo.png')"
          alt="harga kurs logo"
        ></b-image>
        <b-menu class="is-size-7 has-text-weight-medium">
          <b-menu-list>
            <router-link :to="{ name: 'Dashboard' }" class="side-menu">
              <div
                class="link-container"
                v-if="$route.path.includes('/dashboard')"
              >
                <img
                  src="../assets/sidebar-dashboard/Dashboard_white.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Dashboard
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/Dashboard.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Dashboard
              </div>
            </router-link>
          </b-menu-list>
          <b-menu-list
            v-if="
              currentAdmin.role != null &&
                (currentAdmin.role.proliferasiIndex ||
                  currentAdmin.role.dttotIndex)
            "
          >
            <router-link
              :to="{ name: 'DTTOTIndex' }"
              v-if="currentAdmin.role != null && currentAdmin.role.dttotIndex"
              class="side-menu"
            >
              <div
                class="link-container"
                v-if="
                  $route.path.includes('/dttot') ||
                    $route.path.includes('/proliferasi')
                "
              >
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                DTTOT & Proliferasi WMD
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                DTTOT & Proliferasi WMD
              </div>
            </router-link>

            <!-- <router-link
              to="/dttot&proliferasi/proliferasi"
              v-else-if="
                currentAdmin.role != null && currentAdmin.role.proliferasiIndex
              "
              class="side-menu"
            >
              <div
                class="link-container"
                v-if="
                  $route.path.includes('/dttot') ||
                    $route.path.includes('/proliferasi')
                "
              >
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                DTTOT & Proliferasi WMD
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                DTTOT & Proliferasi WMD
              </div>
            </router-link> -->
          </b-menu-list>

          <!-- <b-menu-list>
            <router-link to="/proliferasi" class="side-menu">
              <div class="link-container">
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                  v-if="$route.path.includes('/proliferasi')"
                />
                <img
                  src="../assets/Warning_img.png"
                  alt=""
                  class="side-menu-icon mr-2"
                  v-else
                />
                Proliferasi WMD
              </div>
            </router-link>
          </b-menu-list> -->

          <b-menu-list>
            <router-link
              :to="{ name: 'BillingIndex' }"
              class="side-menu"
              v-if="currentAdmin.role != null && currentAdmin.role.billingIndex"
            >
              <div
                class="link-container"
                v-if="$route.path.includes('/billings')"
              >
                <img
                  src="../assets/sidebar-dashboard/Add_Ons_white.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Billings
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/Add_Ons.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Billings
              </div>
            </router-link>
          </b-menu-list>

          <b-menu-list>
            <router-link
              :to="{ name: 'TenantIndex' }"
              class="side-menu"
              v-if="currentAdmin.role != null && currentAdmin.role.tenantIndex"
            >
              <div
                class="link-container"
                v-if="$route.path.includes('/tenant')"
              >
                <img
                  src="../assets/sidebar-dashboard/Tenant_Management.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Tenant Management
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/Tenant_Management.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Tenant Management
              </div>
            </router-link>
          </b-menu-list>

          <b-menu-list>
            <router-link :to="{ name: 'AddOnsIndex' }" class="side-menu">
              <div
                class="link-container"
                v-if="$route.path.includes('/addons')"
              >
                <img
                  src="../assets/sidebar-dashboard/Add-Ons_Management.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Add-Ons Management
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/Add-Ons_Management.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Add-Ons Management
              </div>
            </router-link>
          </b-menu-list>

          <b-menu-list>
            <router-link :to="{ name: 'ReportIndex' }" class="side-menu">
              <div
                class="link-container"
                v-if="$route.path.includes('/reporting')"
              >
                <img
                  src="../assets/sidebar-dashboard/Report_white.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Reporting
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/Report.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Reporting
              </div>
            </router-link>
            <b-menu-list>
              <div
                class="report-child ml-5 mr-4"
                v-if="$route.path.includes('/reporting')"
              >
                <router-link :to="{ name: 'ReportRevenue' }">
                  <div
                    class="link-container"
                    v-if="$route.path.includes('/reporting/revenue')"
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons_white.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Revenue
                  </div>
                  <div
                    class="link-container has-text-primary-medium-blue"
                    v-else
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Revenue
                  </div>
                </router-link>
              </div>
            </b-menu-list>
            <b-menu-list>
              <div
                class="report-child ml-5 mr-4"
                v-if="$route.path.includes('/reporting')"
              >
                <router-link :to="{ name: 'ReportAddon' }">
                  <div
                    class="link-container "
                    v-if="$route.path.includes('/reporting/addon')"
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons_white.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Add-Ons
                  </div>
                  <div
                    class="link-container has-text-primary-medium-blue"
                    v-else
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Add-Ons
                  </div>
                </router-link>
              </div>
            </b-menu-list>
            <b-menu-list>
              <div
                class="report-child ml-5 mr-4"
                v-if="$route.path.includes('/reporting')"
              >
                <router-link :to="{ name: 'ReportBilling' }">
                  <div
                    class="link-container "
                    v-if="$route.path.includes('/reporting/billing')"
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons_white.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Billings
                  </div>
                  <div
                    class="link-container has-text-primary-medium-blue"
                    v-else
                  >
                    <img
                      src="../assets/sidebar-dashboard/Add_Ons.png"
                      alt=""
                      class="side-menu-icon mr-2"
                    />
                    Billings
                  </div>
                </router-link>
              </div>
            </b-menu-list>
          </b-menu-list>

          <hr />
          <b-menu-list>
            <router-link
              :to="{ name: 'Settings' }"
              class="side-menu"
              v-if="
                currentAdmin.role != null &&
                  (currentAdmin.role.settingsUserIndex ||
                    currentAdmin.role.settingsRoleIndex)
              "
            >
              <div
                class="link-container"
                v-if="$route.path.includes('/settings')"
              >
                <img
                  src="../assets/sidebar-dashboard/settings_white.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Settings
              </div>
              <div class="link-container has-text-primary-medium-blue" v-else>
                <img
                  src="../assets/sidebar-dashboard/settings.png"
                  alt=""
                  class="side-menu-icon mr-2"
                />
                Settings
              </div>
            </router-link>
          </b-menu-list>

          <b-menu-list>
            <router-link to="#" class="side-menu">
              <span @click="logout">
                <div class="link-container has-text-primary-medium-blue">
                  <img
                    src="../assets/sidebar-dashboard/Logout.png"
                    alt=""
                    class="side-menu-icon mr-2"
                  />
                  Logout
                </div>
              </span>
            </router-link>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>

    <div
      :style="[open ? { 'margin-left': '260px' } : { 'margin-left': '0px' }]"
    >
      <NavbarDashboard></NavbarDashboard>
      <section
        class="section has-background-primary-dim-blue-transparent"
        style="min-height:88vh"
      >
        <!-- {{ windowWidth }} -->
        <b-button @click="open = !open" class="is-hidden-tablet">
          {{ open ? 'hide' : 'show' }}
        </b-button>
        <router-view></router-view>
      </section>
    </div>
  </section>
</template>

<script>
import NavbarDashboard from '@/components/NavbarDashboard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavbarDashboard,
  },
  data() {
    return {
      open: false,
      overlay: false,
      reduce: false,
      canCancel: false,
      windowWidth: window.innerWidth,
      isLoading: true,
    }
  },
  methods: {
    logout: function(e) {
      e.preventDefault()
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$buefy.toast.open({
            message: 'Berhasil Logout',
            type: 'is-success',
          })
          this.$router.push('/')
        })
        .catch(err => console.log(err))
    },
    onResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 769) {
        this.overlay = true
        this.open = false
        this.canCancel = true
      } else {
        this.overlay = false
        this.open = true
        this.canCancel = false
      }
    },
  },
  beforeCreate() {
    // this.$store.dispatch('getcurrentAdmin').then(() => {
    //   if (this.$route.meta.name == 'Kasir') {
    //     if (this.$store.getters.currentAdmin.role.id != 1) {
    //       this.$router.push('/dashboard')
    //     }
    //   }
    //   if (this.$route.meta.middleware) {
    //     if (
    //       !this.$store.getters.currentAdmin.role[
    //         this.$route.meta.middleware.auth
    //       ]
    //     ) {
    //       this.$router.push('/dashboard')
    //     }
    //   }
    // })
  },

  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
    this.isLoading = false
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
  },
}
</script>

<style lang="scss">
.p-1 {
  padding: 1em !important;
}

// .menu-items {
//   display: flex;
//   flex-direction: column;
//   height: 85%;
//   margin: 0px 12px 0px 12px;
// }

// .menu-items > * {
//   margin-top: 13px;
// }

.link-container {
  padding: 2px;
}

.side-menu-icon {
  margin-left: 3px;
  width: 20px;
}
.section {
  padding: 1.5rem 1.5rem;
}
</style>
