<template>
  <b-navbar spaced v-if="currentAdmin.admin != null">
    <template slot="start">
      <h3
        class="is-size-4 has-text-primary-dark-blue"
        style="border-bottom: 5px solid;"
      >
        {{ $route.meta.name }}
      </h3>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <router-link :to="{ name: 'Settings' }">
          <img
            :src="require('../assets/navbar-dashboard/settings.png')"
            alt=""
            class="settings-icon"
          />
        </router-link>
        <div>
          <div class="notification mr-2" id="notification">
            <img
              src="../assets/navbar-dashboard/Notification.png"
              alt=""
              class="notification-icon"
              @mouseenter="onShow()"
            />
          </div>
          <div
            v-show="showNotification"
            @mouseleave="onHidden()"
            style="position:absolute;margin-left:-250px;margin-top:10px;width:300px;border-style: none; border-radius: 5px;box-shadow: 0 0 0 1px rgba(150, 150, 154, 0.2);"
            class="has-background-white"
          >
            <div
              class=" columns pr-3 pt-3 pl-3 mb-0 is-size-6"
              style="align-items:flex-end"
            >
              <div class="column is-7 ">
                <strong
                  class="has-text-primary-dark-blue"
                  style="text-decoration:none"
                >
                  Notifications
                </strong>
              </div>
              <div class="column is-5 has-text-right">
                <router-link
                  to="/notification"
                  class="has-text-primary-dark-blue"
                  style="text-decoration:none"
                >
                  View All
                </router-link>
              </div>
            </div>
            <hr class="notification-hr" style="margin:0px 0px" />
          </div>
        </div>
        <img
          src="../assets/navbar-dashboard/Ellipse3.png"
          alt="Avatar"
          class="avatar"
          style="object-fit: cover;"
        />
        {{ currentAdmin.admin.name }}
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      notifications: [],
      showNotification: false,
      dateFormat: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['currentAdmin']),
  },
  methods: {
    getRecentNotification: function() {
      this.$store.dispatch('getRecentNotification').then(response => {
        this.notifications = response
      })
    },
    updateNotification: function() {
      this.$store.dispatch('updateNotification')
    },
    onShow: function() {
      this.showNotification = true
      this.getRecentNotification()
    },
    onHidden: function() {
      this.showNotification = false
      this.updateNotification()
      this.getRecentNotification()
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.settings-icon {
  width: 25px;
  height: 25px;
}
.notification {
  margin: 0px 0px 0px 12px;
  background-color: rgba(48, 207, 242, 0.2);
  border-radius: 30px;
  padding: 2px 15px 5px 15px;
  text-decoration: none;
  display: inline-block;
}
.notification-icon {
  width: 20px;
  height: 20px;
  margin-right: 0px;
  margin-top: 5px;
}
.notification:hover {
  text-decoration: none;
}
.notification-item {
  float: left;
  height: 30px;
  width: 30px;
  margin-bottom: 5px;
  position: relative;
  background-color: rgba(48, 207, 242, 0.2);
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
}

/* This will style any <img> element in .notification-item div */
.notification-item img {
  height: 70%;
  width: 70%;
  vertical-align: middle;
}
</style>
